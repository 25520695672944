:root {
  --mdc-theme-primary: #7caf00;
  --mib-theme-primarylight: #afe147;
  --mib-theme-primarydark: #497f00;
  --mib-theme-on-primarydark: #fff;
  --mdc-theme-secondary: #e89000;
  --mib-theme-secondarylight: #ffc045;
  --mib-theme-secondarydark: #b06200;
  --mib-theme-on-secondarydark: #fff;
  --mib-theme-lightdark: #aeaeae;
  --mib-theme-lightlight: #e9e9e9;
  --mib-theme-on-lightdark: #000;
  --mib-theme-darkdark: #1b1b1b;
  --mib-theme-darklight: #6d6d6d;
  --mib-theme-on-darkdark: ;
  --mdc-theme-error: #b00020;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mib-theme-light: #E0E0E0;
  --mib-theme-dark: #424242;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-surface: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-error: #fff;
  --mib-theme-on-light: #555;
  --mib-theme-on-dark: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}
:focus {outline:none;}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
